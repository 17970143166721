import { Typography, Container, useMediaQuery } from '@mui/material';
import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { HOME } from '../../data/analytics';

import homeBackground from '../../images/Home/homeBackground.webp';

import theme from '../../theme';
import PageHeader from '../../components/pageHeader/PageHeader.jsx';
import HomeStatsCard from '../../components/homeStatsCard/HomeStatsCard.jsx';
import NewsCarousel from '../../components/newsCarousel/NewsCarousel.jsx';
import FeaturedNewsCard from '../../components/featuredNewsCard/FeaturedNewsCard.jsx';

import { NEWS_ARTICLES } from '../../data/newsArticles';
import usePageWidth from '../../utils/usePageWidth';
import usePadding from '../../utils/usePadding';

const Home = () => {
  return (
    <Trackable interfaceId={HOME.INTERFACE_ID} loadId={HOME.ON_LOAD}>
      <PageHeader image={homeBackground}>
        <Typography variant="h5" color="#FFF">
          DPD's Sustainable Initiatives
        </Typography>
        <Typography variant="h1" color="#FFF">
          Sustainable Delivery
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          width: usePageWidth(),
          px: usePadding(),
          pt: useMediaQuery(theme.breakpoints.down('md')) ? 6 : 12,
          pb: 6,
        }}
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
        >
          Our 2025 Dashboard
        </Typography>
        <Typography variant="body1" sx={{ pb: 2 }}>
          Here's an up-to-date view of our all-electric deliveries and
          associated carbon savings. We've included other key sustainability
          statistics to keep you fully informed
        </Typography>
        <HomeStatsCard />
        <Typography variant="body1" sx={{ pt: 2 }}>
          * CO2e saving compared to use of standard diesel
        </Typography>
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
          sx={{ pt: 10 }}
        >
          Latest News
        </Typography>
        <FeaturedNewsCard article={NEWS_ARTICLES[0]} />
      </Container>
      <NewsCarousel
        background={theme.palette.primary.background}
        items={NEWS_ARTICLES.slice(1)}
        itemsPerPage={3}
        nextScrollId={HOME.NEXT_SCROLL}
        backScrollId={HOME.BACK_SCROLL}
        readMoreId={HOME.READ_MORE}
      />
    </Trackable>
  );
};

export default Home;
