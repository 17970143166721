import {
  Typography,
  Container,
  Box,
  useMediaQuery,
  Card,
  CardContent,
  CardMedia,
} from '@mui/material';

import { Trackable } from '@dpdgroupuk/react-event-tracker';
import { ECO_FUND } from '../../../data/analytics';

import ecoHeader from '../../../images/Social/ecoHeader.webp';
import ecoColliersWoodBackground from '../../../images/Social/ecoColliersWoodBackground.png';

import theme from '../../../theme';
import PageHeader from '../../../components/pageHeader/PageHeader.jsx';
import SideBySide from '../../../components/sideBySide/SideBySide.jsx';

import {
  ECO_FUND_FORESTRY,
  RSPB_VIDEO,
  FORESTRY_PODCAST,
  CITY_TO_SEA_PODCAST,
} from '../../../data/links';
import usePageWidth from '../../../utils/usePageWidth';
import usePadding from '../../../utils/usePadding';

import treeapp2024 from '../../../images/Social/treeapp2024.png';
import cityToSea from '../../../images/Social/cityToSea.png';
import codyDock from '../../../images/Social/codyDock.png';

import ActionButton from '../../../components/actionButton/ActionButton.jsx';
import ExternalLink from '../../../components/externalLink/ExternalLink.jsx';

const linkStyles = {
  color: theme.palette.primary.core,
  textDecorationColor: theme.palette.primary.core,
  '&:hover': {
    color: theme.palette.primary.core,
    textDecorationColor: theme.palette.primary.core,
  },
};

const EcoFund = () => {
  return (
    <Trackable interfaceId={ECO_FUND.INTERFACE_ID} loadId={ECO_FUND.ON_LOAD}>
      <PageHeader image={ecoHeader}>
        <Typography variant="h5" color="#FFF" gutterBottom>
          Social
        </Typography>
        <Typography variant="h1" color="#FFF">
          DPD Eco Fund
        </Typography>
      </PageHeader>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography variant="h4Light" color="#000" gutterBottom>
            The Eco Fund was established to utilise the revenue from recycling
            specific waste streams generated by our operations to support
            business and charitable endeavours in the fight against climate
            change. Through this fund we have supported both short and long term
            projects, the recipients being selected through submission to our
            Eco Fund Committee on a quarterly basis.
          </Typography>
          <br />
          <Typography variant="h4Light" color="#000" gutterBottom>
            In 2024 we donated a record amount of funding from our Eco Fund:
            £323,701!
          </Typography>
        </Box>
      </Container>
      <Container
        sx={{
          minWidth: '100%',
          backgroundColor: theme.palette.primary.background,
          display: 'flex',
          justifyContent: 'center',
          pt: 10,
        }}
      >
        <Card
          raised={false}
          elevation={0}
          sx={{
            display: 'flex',
            width: usePageWidth(),
            flexDirection: useMediaQuery(theme.breakpoints.down('md'))
              ? 'column'
              : 'row',
            borderRadius: '10px',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              backgroundColor: theme.palette.primary.background,
            }}
          >
            <CardContent sx={{ flex: '1 0 auto' }}>
              <Typography
                variant="h2Light"
                color={theme.palette.primary.core}
                gutterBottom
              >
                TreeApp
              </Typography>
              <Typography variant="h5Light" color="#000">
                2024 was a milestone year in our partnership with Treeapp, as we
                surpassed 300,000 trees planted since the initiative began!
              </Typography>
              <br />
              <Typography variant="h5Light" color="#000">
                Treeapp guarantees that reforestation initiatives have a direct
                and positive impact on local communities, with contracts
                securing long-term land ownership. This, coupled with our
                meticulous selection of native species and the continuous care
                and maintenance of our planting sites, has yielded remarkable
                results.
              </Typography>
              <br />
              <Typography variant="h5Light" color="#000">
                An example of a highly successful planting project that emerged
                from this partnership is the coastal afforestation initiative on
                Lombok Island, Indonesia. We provided vital funding for
                extensive mangrove planting efforts, focusing on the large-scale
                planting of various local species to combat soil erosion. This
                initiative has been instrumental in restoring the island’s
                coastal ecosystems.
              </Typography>
              <br />
              <Typography variant="h5Light" color="#000">
                A key highlight of the project was its educational outreach,
                which involved collaborating with five local schools to teach
                children about the importance of protecting coastal areas. This
                focus on educating future generations has fostered a deeper
                understanding of environmental stewardship within the community.
                As a result, the project has not only revitalised local
                fisheries and coral reefs but also delivered significant
                benefits to both the environment and the local population.
              </Typography>
              <br />
            </CardContent>
          </Box>
          <CardMedia
            component="img"
            sx={{
              objectFit: 'contain',
              width: useMediaQuery(theme.breakpoints.down('md')) ? '100%' : 325,
              backgroundColor: theme.palette.primary.background,
            }}
            image={treeapp2024}
            alt="TreeApp logo"
          />
        </Card>
      </Container>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.core}
            gutterBottom
          >
            RSPB Pulborough Brooks
          </Typography>
          <Typography variant="h4Light" color="#000" gutterBottom>
            Pulborough Brooks, in the heart of the Arun Valley, is a Site of
            Special Scientific Interest (SSSI). The Arun Valley is one of the
            most biodiverse floodplain wetlands in England. We have lost 90% of
            our wetlands habitats in the last 100 years and these play a crucial
            role in our resilience to climate change.
          </Typography>
          <br />
          <Typography variant="h4Light" color="#000" gutterBottom>
            We were delighted to be able to award £50,000 to the RSPB to carry
            out track repairs and replace and enhance the water control
            structures on site. You can find out more in our video below where
            we visited the site and got a first-hand account of the impact our
            funding will have.
          </Typography>
          <br />
          <iframe
            title={RSPB_VIDEO}
            src={RSPB_VIDEO}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height="550px"
            width="100%"
            style={{ margin: '2rem 0' }}
          />
        </Box>
      </Container>
      <SideBySide
        image={cityToSea}
        imageSide="right"
        background={theme.palette.secondary.backgroundWebApp}
        verticalPadding={25}
        mobileDirection="column-reverse"
      >
        <Typography
          variant="h2Light"
          color={theme.palette.primary.core}
          gutterBottom
          sx={useMediaQuery(theme.breakpoints.down('md')) && { paddingTop: 6 }}
        >
          City to Sea
        </Typography>
        <Typography variant="h4Light" color="#000" gutterBottom>
          World Refill Day began in 2017 with the aim of preventing plastic
          pollution at the source by encouraging people to use reusable and
          refillable solutions over single-use items.
        </Typography>
        <Typography variant="body1" color="#000" gutterBottom>
          We donated £16,000 to support the expansion of the refill campaign
          across UK communities, including delivery of monthly training sessions
          and behaviour change guidance.
        </Typography>
        <br />
        <Typography variant="body1" color="#000" gutterBottom>
          You can learn more about the Refill campaign and the work that City to
          Sea do by tuning in to our podcast with City to Sea CEO Jane Martin.
        </Typography>
        <br />
        <ActionButton
          text="Listen Now"
          onclick={() => {
            window.open(CITY_TO_SEA_PODCAST, '_blank');
          }}
        />
      </SideBySide>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.core}
            gutterBottom
          >
            Cody Dock
          </Typography>
          <Typography variant="h4Light" color="#000" gutterBottom>
            Situated next to our Docklands site, Cody Dock is a charity and
            social enterprise formed to act as a vehicle for the community-led
            regeneration of Cody Dock and the local waterways. With access to
            green space, a visitor centre, café, community-led projects, artists
            studios, and over 6,000 volunteers, Cody Dock is an important piece
            of the local community and the charity is responsible for the
            extensive regeneration of the dock and the local ecology.
          </Typography>
          <br />
          <Typography variant="h4Light" color="#000" gutterBottom>
            The Eco Fund committee were pleased to have the opportunity to
            support our neighbour at Docklands by awarding £50,000 for the
            creation of a Kingfisher and Sand Martin habitat at Cody Dock. We
            look forward to seeing how this develops in 2025!
          </Typography>
          <br />
          <Box component="img" src={codyDock} sx={{ maxWidth: '70%' }} />
        </Box>
      </Container>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          backgroundImage: `url(${ecoColliersWoodBackground})`,
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          backgroundSize: 'cover',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography variant="h2Light" color="#FFF" gutterBottom>
            Forestry England
          </Typography>
          <Typography variant="h4Light" color="#FFF" gutterBottom>
            We have been fortunate to work with Forestry England for nearly 4
            years, planting at sites across the UK to support their “Growing the
            Future” plan. Most recently planting took place at Dimmingsdale, a
            Planted Ancient Woodland Site (PAWS) forming part of the Churnet
            Valley in the Staffordshire Moorlands. Forestry England is committed
            to restoring these PAWS back to native broadleaf, with the result of
            expanding the wooded area at this site which is enjoyed by around
            half a million visitors a year.
          </Typography>
          <br />
          <Typography variant="h4Light" color="#FFF" gutterBottom>
            We were able to visit Dimmingsdale in 2024 to see the planting that
            had taken place and also get involved in some site maintenance by
            removing invasive species. You can learn more about this vital work
            through our video or listening to our{' '}
            <ExternalLink to={FORESTRY_PODCAST} styles={linkStyles}>
              podcast
            </ExternalLink>
            .
          </Typography>
          <br />
          <iframe
            title={ECO_FUND_FORESTRY}
            src={ECO_FUND_FORESTRY}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            height={
              useMediaQuery(theme.breakpoints.down('md')) ? '200px' : '550px'
            }
            width="100%"
          />
        </Box>
      </Container>
      <Container
        disableGutters
        sx={{
          minWidth: '100%',
          py: useMediaQuery(theme.breakpoints.down('md')) ? 4 : 15,
          backgroundColor: theme.palette.secondary.background,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box sx={{ px: usePadding(), width: usePageWidth() }}>
          <Typography
            variant="h2Light"
            color={theme.palette.primary.core}
            gutterBottom
          >
            Woodland Trust
          </Typography>
          <Typography variant="h4Light" color="#000" gutterBottom>
            Following our initial funding round in 2024, we decided to ask our
            colleagues what causes meant the most to them to make sure that not
            only were the projects we supported aligned with our strategic
            goals, but they also contributed to our colleagues' pride in working
            for DPD.
          </Typography>
          <br />
          <Typography variant="h4Light" color="#000" gutterBottom>
            After a very close vote, the winning cause was deforestation. A
            number of funding options were presented to the committee, and
            through our final funding round of 2024 we awarded £30,000 each to
            two projects with the Woodland Trust.
          </Typography>
          <br />
          <Typography variant="h4Light" color="#000" gutterBottom>
            Woodland Trust has provided opportunities for schools and
            communities to plant trees, no matter how big or small their piece
            of land. The tree packs are available for free and there are a
            number of options depending on the area available. We are delighted
            to have been able to sponsor over 9,000 of these trees with our
            donation, ensuring that everyone has access to planting
            opportunities, regardless of location or socio-economic background.
          </Typography>
          <br />
          <Typography variant="h4Light" color="#000" gutterBottom>
            We were also able to support the protection and restoration of 15
            hectares of UK rainforests. Rainforests in the UK are part of the
            coastal temperate rainforest biome and are havens of biodiversity,
            alongside providing refuge in the face of climate change. Management
            of these areas is essential to prevent invasive species and to
            protect against disease and overgrazing.
          </Typography>
        </Box>
      </Container>
    </Trackable>
  );
};

export default EcoFund;
