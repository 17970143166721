import treeappImpactReport2024Pdf from './TreeappImpactReport2024.pdf';
import sustainabilityPolicy2024Pdf from './SustainabilityPolicy2024.pdf';
import sustainabilityPolicy2025Pdf from './SustainabilityPolicy2025.pdf';
import carbonCalculator2025Pdf from './CarbonCalculator2025.pdf';
import sustainabilityInFocusPdf from './SustainabilityInFocus2023.pdf';
import ISO14001Pdf from './ISO14001.pdf';
import reducingCO2Image from './reducing-co2-year-on-year.png';
import reducingWasteImage from './reducing-waste-year-on-year.png';
import inspireBookletPdf from './inspireBooklet.pdf';
import sustainabilityGlossaryPdf from './sustainabilityGlossary.pdf';
import netZeroApporovalLetterPdf from './GeoPost-DPDgroup-Net-Zero-Approval-Letter.pdf';
import sbtiNetZeroCertificate from './geopost-DPDgroup-Certificate.pdf';
import geopostEcoVadisCertificate from './geopost-ecovadis-certificate.pdf';
import sustainabilityReport2023Pdf from './SustainabilityReport2023.pdf';

export const sustainabilityReport2023 = sustainabilityReport2023Pdf;
export const treeappImpactReport2024 = treeappImpactReport2024Pdf;
export const sustainabilityPolicy2024 = sustainabilityPolicy2024Pdf;
export const sustainabilityPolicy2025 = sustainabilityPolicy2025Pdf;
export const carbonCalculator2025 = carbonCalculator2025Pdf;
export const sustainabilityInFocus = sustainabilityInFocusPdf;
export const ISO14001 = ISO14001Pdf;
export const reducingCO2 = reducingCO2Image;
export const reducingWaste = reducingWasteImage;
export const inspireBooklet = inspireBookletPdf;
export const sustainabilityGlossary = sustainabilityGlossaryPdf;
export const netZeroApproval = netZeroApporovalLetterPdf;
export const SBTiCertificate = sbtiNetZeroCertificate;
export const ecovadisCertificate = geopostEcoVadisCertificate;
