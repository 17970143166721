import React from 'react';
import {
  Card,
  Box,
  Grid,
  Typography,
  Button,
  useMediaQuery,
} from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { useTracker } from '@dpdgroupuk/react-event-tracker';
import { HOME } from '../../data/analytics';

import theme from '../../theme';

const FeaturedNewsCard = ({ article }) => {
  const navigate = useNavigate();
  const tracker = useTracker();

  // Call the useMediaQuery hook unconditionally and store the result in a variable.
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  const goToArticle = () => {
    navigate(`/news/${article.id}`);
    window.scrollTo(0, 0);
  };

  const subtitle =
    article.subtitle.length > 200
      ? `${article.subtitle.slice(0, 200)}...`
      : article.subtitle;

  return (
    <Card
      sx={{
        borderRadius: 1,
        backgroundColor: '#FFF',
        boxShadow: '0px 36px 142px rgba(56, 90, 79, 0.15)',
      }}
    >
      <Grid
        container
        alignItems="flex-start"
        sx={{ maxHeight: !isMobile && 656 }} // Use the variable here
      >
        {article.image && (
          <Grid item md={6} xs={12}>
            <Box
              component="img"
              src={isMobile ? article.mobileImage : article.image}
              sx={{ width: '100%' }}
            />
          </Grid>
        )}
        <Grid item md={article.image ? 6 : 12} xs={12} sx={{ p: 4.5 }}>
          <Typography
            variant="body1"
            color={theme.palette.primary.accentCore}
            paddingBottom={2}
          >
            {article.date}
          </Typography>
          <Typography
            variant={isMobile ? 'h3Light' : 'h2Light'}
            color={theme.palette.primary.core}
            paddingBottom={2}
          >
            {article.title ? article.title : article.cardTitle}
          </Typography>
          <Typography variant="h4Light" color="#000" paddingBottom={2}>
            {subtitle}
          </Typography>
          {article.subtitle.length <= 200 && (
            <Typography
              marginBottom={2}
              sx={{
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                display: '-webkit-box !important',
                WebkitLineClamp: 3,
                WebkitBoxOrient: 'vertical',
                marginBottom: 2,
              }}
            >
              {article.content}
            </Typography>
          )}
          <Button
            onClick={() => {
              goToArticle();
              tracker.logEvent(HOME.READ_MORE);
            }}
            sx={{
              color: '#FFF',
              backgroundColor: theme.palette.primary.core,
              borderRadius: 1,
              float: 'right',
              py: '15px',
              px: '30px',
              '&:hover': {
                backgroundColor: theme.palette.primary.core,
                opacity: 0.8,
              },
            }}
          >
            <Typography variant="button">Read More</Typography>
          </Button>
        </Grid>
      </Grid>
    </Card>
  );
};

FeaturedNewsCard.propTypes = {
  article: PropTypes.object,
};

export default FeaturedNewsCard;
